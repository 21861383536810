import ubaid from "../images/ubaid.jpg"
import resume from "../assets/resume.pdf"

function About() {
    return (
        <div className="container">
            <main className="pb-5">
                <div className="other title">Ubaid Delawala</div>
                <h2>Background</h2>
                <div>
                    <figure className="profile-image mb-5">
                        <img src={ubaid} alt="Profile Pic" className="profile-image" />
                        <figcaption className="figure-caption">
                            <a href={resume} className="btn btn-dark w-100 mt-2" target={"_blank"} rel="noreferrer">Resume</a>
                        </figcaption>
                    </figure>
                    <div className="text">
                        My name is Ubaid Delawala and I was born and raised in Zambia. I graduated from LICEF Secondary school in December 2019.
                        Then I moved to Canada in January 2021. I enrolled in Software Engineering Technology Advanced Diploma program at Centennial College,
                        from which I graduated in December 2022.
                    </div>
                    <h2>Experience</h2>
                    <p>
                        During this time in college, I have learned plenty of valuable skills and lessons; I am a passionate learner.
                        I have experience in working in a team environment as well as few other skills mentioned below:
                        <br />
                        <ul>
                            <li>Programming  (C#, Java, Python)</li>
                            <li>Web Application Development</li>
                            <li>Api Engineering and Cloud Computing</li>
                            <li>Database Management</li>
                        </ul>
                    </p>
                </div>
            </main>
        </div>
    )
}

export default About
