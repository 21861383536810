function Home() {
    return (
        <div className="container">
            {/* <main> */}
            <div className="home title">Welcome to my Portfolio site!</div>
            
            <a href="/about"><button type="button" class="container aboutBtn"><span className="  blinking-element">About Me</span></button></a>

            {/* <p className="text-center">I am a graduate in software engineering technology with an advanced diploma. I have experience in creating web, windows and android applications using the latest technologies.</p> */}

            {/* <div class="row">
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-center">Projects</h5>
                                <p class="card-text">View a list of projects I have completed</p>
                                <a href="/projects" class="btn btn-dark w-100">My Projects</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-center">About Me</h5>
                                <p class="card-text">Know more about my background and studies</p>
                                <a href="/about" class="btn btn-dark w-100">About Me</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title text-center">Contact Me</h5>
                                <p class="card-text">Want to get in contact? Send me an email and check out my socials!</p>
                                <a href="/contact" class="btn btn-dark w-100">Contact Me</a>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* </main> */}
        </div>
    )
}

export default Home