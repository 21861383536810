import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import bmrImage from "../images/bmr.jpg"
import weatherImage from "../images/weather.png"
import realEstate from "../images/realestate.png"
import bugSmasher from "../images/bugSmasher.png"

const Projects = () => {
    const projects = [
        {
            name: 'Weather and Location',
            image: weatherImage,
            description: 'Weather and Geaolocation using APIs, built with ReactJS',
            link: 'https://ubaid-weather-location.netlify.app/'
        },
        {
            name: 'Realtor Website',
            image: realEstate,
            description: 'Realtor Static Website',
            link: 'https://ubzzy.github.io/RealEstateWebsite/'
        },
        {
            name: 'Bug Smasher',
            image: bugSmasher,
            description: 'Bug Smasher Game built with VanillaJS',
            link: 'https://ubzzy.github.io/BugSmasher/'
        },
        {
            name: 'BMR Calculator',
            image: bmrImage,
            description: 'Calculate Basal Metabolic Rate in Metric and Imperial',
            link: 'https://ubzzy.github.io/BMRCalculator/'
        }
    ];

    return (
        <Container>
            <Row>
                {projects.map(project => (
                    <Col xs={12} md={6} lg={6} key={project.name}>
                        <Card>
                            <Card.Body>
                                <Card.Title className='text-center'>{project.name}</Card.Title>
                                <a href={project.link} target={"_blank"} rel="noreferrer">
                                    <Image src={project.image} thumbnail />
                                </a>
                                <Card.Text>{project.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Projects;
