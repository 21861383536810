import instagramLogo from '../icons/instagram.png';
import linkedinLogo from '../icons/linkedin.png';
import githubLogo from '../icons/github.png';

function Contact() {
    return (
        <div className="container">
            <main>
                <div className="other title">Where you can find me</div>

                <div className='text-center'>
                    <p>delawalaubaid@gmail.com</p>
                    <p>+1 (647) 786-5209</p>
                    <p>Toronto, Canada</p>
                    <p>
                        <a href='https://www.linkedin.com/in/delawala-ubaid/' target={'_blank'} rel="noreferrer">
                            <img src={linkedinLogo} alt="LinkedIn logo" className='icon  me-2' />
                        </a>
                        <a href='https://github.com/ubzzy' target={'_blank'} rel="noreferrer">
                            <img src={githubLogo} alt="LinkedIn logo" className='icon  me-2' />
                        </a>
                        <a href='https://www.instagram.com/itz_ubzzy/' target={'_blank'} rel="noreferrer">
                            <img src={instagramLogo} alt="Instagram logo" className='icon' />
                        </a>
                    </p>
                </div>
            </main>
        </div>
    )
}

export default Contact