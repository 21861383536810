import { Link, useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../images/logo.png'

function Header() {
    // Get the location object from the useLocation hook
    const location = useLocation();

    return (
        <Navbar expand="sm" className='navbar navbar-expand-lg navbar-light' >
            <Navbar.Brand href="home" className='ms-4'>
                <img src={logo} alt="logo" className="logo"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto me-2">
                    <Nav.Link as={Link} to="/home" active={location.pathname === '/home'}>Home</Nav.Link>
                </Nav>
                <Nav className="me-2">
                    <Nav.Link as={Link} to="/projects" active={location.pathname === '/projects'}>Projects</Nav.Link>
                </Nav>
                <Nav className="me-2">
                    <Nav.Link as={Link} to="/about" active={location.pathname === '/about'}>About Me</Nav.Link>
                </Nav>
                <Nav className="me-2">
                    <Nav.Link as={Link} to="/contact" active={location.pathname === '/contact'}>Contact Me</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header